// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(md) {
	#nav-main {
		display: block !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	height: px(150);
	background-color: $wh-a;
	box-shadow: 0 3px 10px rgba(0,0,0,0.05);
	transition: all 0.2s ease-in-out;
	@include media-breakpoint-down(lg) {
		height: px(165);
	}
	@include media-breakpoint-down(md) {
		height: px(120);
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		min-height: px(70);
		#nav-mobile-trigger {
			position: absolute;
			top: px(20);
			right: px(15);
			background-color: $bl-a;
			width: px(50);
			height: px(50);
		}
	}
	@include media-breakpoint-down(xs) {
		#nav-mobile-trigger {
			top: px(10);
		}
	}
	.container {
		position: relative;
		#nav-top {
			position: absolute;
			top: 0;
			right: px(25);
			z-index: 10;
			.item {
				display: inline-block;
				background-color: $gy-a;
				color: $bl-a;
				border-radius: 0 0 20px 20px;
				padding: px(5) px(20);
				> * {
					display: inline-block;
				}
				.title {
					font-weight: 700;
					text-transform: uppercase;
					margin-right: px(10);
				}
				.separator {
					&:after {
						content:"|";
						display: inline-block;
						vertical-align: middle;
						position: relative;
						margin-top: px(-2);
						margin-left: px(5);
					}
				}
			}
			@include media-breakpoint-down(lg) {
				background-color: $gy-a;
				width: 100%;
				right: 0;
				text-align: center;
				border-radius: 0 0 20px 20px;
			}
			@include media-breakpoint-down(md) {
				font-size: px(12);
				.item {
					padding: px(5) px(10);
				}
			}
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}
	#logo {
		position: absolute;
		top: px(5);
		display: block;
		left: 0;
		z-index: 3;
		@include media-breakpoint-down(lg) {
			width: px(250);
			top: px(40);
		}
		@include media-breakpoint-down(md) {
			width: px(140);
			top: px(40);
		}
		@include media-breakpoint-down(sm) {
			width: px(140);
			top: auto;
			position: relative;
			left: auto;
			padding: px(10) 0;
		}
		@include media-breakpoint-down(xs) {
			width: px(120);
			top: auto;
			position: relative;
			left: auto;
			padding: px(5) 0;
		}
	}
}
// Navigation :: Main

#nav-main {
	position: relative;
	z-index: 2;
	padding: px(95) 0 0 px(250);
	.item {
		display: inline-block;
		text-align: right;
	}

	.link {
		position: relative;
		display: block;
		color: $bl-a;
		padding: px(10) px(14);
		font-size: px(17);
		font-weight: 700;
		&:after {
			content:"";
			width: calc(100% - 30px);
			height: px(2);
			position: absolute;
			bottom: px(5);
			left: px(15);
			background-color: $bl-a;
			opacity: 0;
			transition: all 0.2s ease-in-out;
		}
	}

	.link:focus,
	.link:hover,
	.link.active {
		&:after {
			opacity: 1;
		}
	}
	@include media-breakpoint-down(lg) {
		padding: px(110) 0 0 px(200);
		.link {
			font-size: px(15);
			padding: px(10);
			&:after {
				width: calc(100% - 20px);
				left: px(10)
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(70) 0 0 px(115);
		.link {
			font-size: px(13);
			padding: px(10) px(5);
			&:after {
				width: calc(100% - 10px);
				left: px(5);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(10) 0;
		background-color: $gy-a;
		display: none;
		&:after,
		&:before {
			content:"";
			width: 100%;
			position: absolute;
			top: 0;
			height: 100%;
			background-color: $gy-a;
		}
		&:before {
			left: -100%;
		}
		&:after {
			right: -100%;
		}
		.item {
			display: block;
			text-align: center;
			&:not(:last-child):after {
				content:"";
				display: block;
				margin: 0 auto;
				width: px(30);
				height: px(2);
				background-color: $bl-a;
			}
		}
		.link {
			font-size: px(16);
			&:after {
				display: none;
			}
		}
	}
}
