// Footer

#footer {
	background-color: $gy-b;
	color: $wh-a;
	padding: px(15) 0 px(10) 0;
	.list {
		li {
			display: inline-block;
			&:first-child:after {
				content:"|";
				display: inline-block;
			}
			a {
				font-size: 12px;
			}
		}
	}
	.pixell {
		position: relative;
		top: px(1);
		font-size: 12px;
		img {
			width: 30px;
			display: inline-block;
			position: relative;
			top: -4px;
			margin-left: 5px;
		}
	}

	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
}
