// Sprite :: Structure

.sprite {
    display: inline-block;
    background-image: url("../images/icons/sprite.png");
    background-repeat: no-repeat;
    vertical-align: text-top;
    background-size: 1100px;
}

.sprite.back-top {
    width: 50px;
    height: 50px;
}

.sprite.back-top {
    background-position: 0 0;
}

.sprite {
    &.map-1,
    &.map-2,
    &.map-3 {
        width: 60px;
        height: 70px;
    }
    &.map-1 {
        background-position: -50px 0;
    }
    &.map-2 {
        background-position: -106px 0;
    }
    &.map-3 {
        background-position: -50px -70px;
    }
    &.icon-1,
    &.icon-2,
    &.icon-3,
    &.icon-4,
    &.icon-5,
    &.icon-6 {
        width: 90px;
        height: 90px;
    }

    &.icon-1 {
        background-position: -170px 0;
    }
    &.icon-2 {
        background-position: -260px 0;
    }
    &.icon-3 {
        background-position: -350px 0;
    }
    &.icon-4 {
        background-position: -440px 0;
    }
    &.icon-5 {
        background-position: -530px 0;
    }
    &.icon-6 {
        background-position: -620px 0;
    }
    &.account-1,
    &.account-2 {
        width: 50px;
        height: 50px;
    }
    &.account-1 {
        background-position: -710px 0;
    }
    &.account-2 {
        background-position: -760px 0;
    }
}
