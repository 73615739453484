// Font family
$font-stack: 'Quicksand', sans-serif;

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity 0.1s ease-in-out;
}

// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: px(39);
  line-height: 1.2em;
  font-weight: 700;
}
.h-like-b {
  font-size: px(27);
  line-height: 1.3em;
  font-weight: 700;
}
.h-like-c {
  font-size: px(22);
  line-height: 1.3em;
  font-weight: 700;
}
.h-like-d {
  font-size: px(18);
  line-height: 1.3em;
  font-weight: 700;
}
@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {
    font-size: px(35);
  }
  .h-like-b {
    font-size: px(24);
  }
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: px(31);
  }
  .h-like-b {
    font-size: px(22);
  }
}
@include media-breakpoint-down(xs) {
  .h-like-a {
    font-size: px(27);
  }
  .h-like-b {
    font-size: px(21);
  }
  .h-like-c {
    font-size: px(19);
  }
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a {}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {}
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}
