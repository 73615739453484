// Body

body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}

html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: $bk-a;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $bk-a;
	}

	button:focus,
	button:hover {
		background-color: $bk-a;
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 300px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Slick :: Home

#slick-wp {}

#slick {}

#slick-wp {
	.item {}

	#slick-arrows {
		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
	// Animation classes
	// .slick-slide .content							{ opacity: 0; transform: translateY(-30px) scale(1); transition: all 0.2s ease-in-out 0.5s;}
	// .slick-slide.animation .content					{ opacity: 1; transform: translateY(0) scale(1); }
}

#slick-logos-wp {
	position: relative;

	.item {
		padding: 0 px(15);
	}

	.item img {
		margin: 0 auto;
	}

	.mask-left,
	.mask-right {
		width: 100px;
		z-index: 1;
		height: 100%;
		@include position(absolute, 0 null null null);
		background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
	}

	.mask-left {
		left: 0;
	}

	.mask-right {
		right: 0;
		transform: rotate(180deg);
	}
	@include media-breakpoint-down(sm) {
		.mask-left,
		.mask-right {
			width: 75px;
		}
	}
}

#footer-bg {
	padding: px(40) 0;
	background-image: url("../images/illustrations/bg-recouvrement.jpg");
	background-size: cover;
	background-position: center;
	color: $wh-a;
	@include media-breakpoint-down(md) {
		padding: px(30) 0;
	}
	@include media-breakpoint-down(xs) {
		padding: px(20) 0;
	}
}

#home-top {
	height: 600px;
	position: relative;
	z-index: 2;
	background-image: url("../images/illustrations/bg-home-top.png");
	background-repeat: no-repeat;
	background-position: center top;
}

#home-top {
	.container {
		position: relative;
		padding-top: px(130);
		height: 100%;

		.title-wp {
			.title-big {
				background-color: $bl-a;
				display: inline-block;
				font-weight: 700;
				color: $wh-a;
				font-size: px(38);
				line-height: 1em;
				padding: px(10) px(15);
			}

			.title-small {
				background-color: $wh-a;
				display: inline-block;
				color: $bl-a;
				font-size: px(20);
				line-height: 1em;
				padding: px(10) px(15);

				&.last {
					padding-top: 0;
				}
			}
		}

		.list-btn {
			> * {
				display: inline-block;
				margin-right: px(10);
			}
		}
	}

	.list-adress {
		position: absolute;
		bottom: px(100);
		right: 0;

		.item {
			display: block;
			transition: all 0.2s ease-in-out;
			&:first-child {
				animation: light1 2s infinite;
			}
			&:last-child {
				animation: light2 2s infinite;
			}
			&:focus,
			&:hover {
				.content {
					left: px(-25);
				}
			}

			.icon-wp {
				width: px(90);
				height: px(90);
				text-align: center;
				background-color: $wh-a;
				border-radius: px(90);
				padding-top: px(10);
				box-shadow: 0 5px 5px rgba(0,0,0,0.1);
				position: relative;
				z-index: 2;
				display: inline-block;
				vertical-align: middle;
			}

			.content {
				position: relative;
				width: px(220);
				vertical-align: middle;
				z-index: 1;
				display: inline-block;
				background-color: $bl-a;
				color: $wh-a;
				padding: px(10) px(25) px(10) px(35);
				left: px(-20);
				box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
				border-radius: 0 50px 50px 0;
				transition: all 0.2s ease-in-out;
			}

			.title {
				font-weight: 700;
				font-size: px(17);
			}

			.phone {
				color: $or-a;
				font-size: px(17);
				font-weight: 400;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.container {
			padding-top: px(80);
		}
	}
	@include media-breakpoint-down(sm) {
		.container {
			padding-top: px(60);

			.title-wp {
				.title-big {
					font-size: px(28);
				}

				.title-small {
					font-size: px(17);

					&.last {
						padding-top: px(5);
					}
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.container {
			padding-top: px(30);
			padding-left: px(30);
			padding-right: px(30);

			.title-wp {
				.title-big {
					display: block;
					font-size: px(22);
					line-height: 1.3em;
				}

				.title-small {
					display: block;
					font-size: px(15);
					line-height: 1.3em;

					&.last {
						padding-top: 0;
					}
				}
			}

			.list-btn {
				.btn-a {
					width: 100%;
					display: block;
				}
			}

			.list-adress {
				position: relative;
				bottom: auto;
				right: auto;

				.item {
					.icon-wp {
						width: px(80);
						height: px(80);
						padding-top: px(4);

						.sprite {
							transform: scale(0.8);
						}
					}

					.content {
						width: px(175);
						padding: px(10) px(20) px(10) px(30);

						.title {
							font-size: px(15);
						}

						.phone {
							font-size: px(13);
						}
					}
				}
			}
		}
	}
}
@keyframes light1 {
  0% {
		opacity: 1;
  }
	50% {
		opacity: 0.8;
	}
  to {
		opacity: 1;
  }
}
@keyframes light2 {
  0% {
		opacity: 0.8;
  }
	50% {
		opacity: 1;
	}
  to {
		opacity: 0.8;
  }
}


.logo-ancr {
	width: px(140);
	height: px(140);
	border-radius: px(140);
	box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.access-link-wp {
	.link {
		&.first {
			background-color: $bl-b;
			transition: all 0.2s ease-in-out;

			&:focus,
			&:hover {
				background-color: shade($bl-b, 30%);
			}
		}

		&:focus,
		&:hover {
			background-color: shade($bl-a, 30%);

			.sprite {
				transform: scale(0.9);
			}
		}
		width: 100%;
		background-color: $bl-a;
		display: table;
		color: $wh-a;
		padding: px(20) 0 px(20) px(20);

		.sprite {
			transition: all 0.2s ease-in-out;
		}

		> * {
			display: table-cell;
			vertical-align: middle;
		}

		.col-right {
			text-align: right;
		}

		.btn-wp {
			background-color: $wh-a;
			font-size: px(13);
			text-transform: uppercase;
			color: $bl-a;
			padding: px(10) px(15);
			border-radius: 30px 0 0 30px;
		}

		.title {
			font-weight: 600;
			padding-left: px(15);
			margin: 0;
		}
		@include media-breakpoint-down(md) {
			.title {
				font-size: px(18);
				padding-left: px(10);
				line-height: 1.2em;
			}

			.btn-wp {
				font-size: px(11);
				padding: px(10);
			}
		}
		@include media-breakpoint-down(sm) {
			.title {
				font-size: px(16);
				padding-left: px(8);
			}

			.btn-wp {
				font-size: px(10);
				padding: px(10);
			}
		}
		@include media-breakpoint-down(sm) {
			.title {
				font-size: px(17);
				padding-left: px(8);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		> .item:first-child {
			padding-right: px(15);
		}

		> .item:last-child {
			padding-left: px(15);
		}

		.item {
			float: left;
			width: 50%;
		}
	}
	@include media-breakpoint-down(xs) {
		> .item:first-child {
			padding-right: px(0);
		}

		> .item:last-child {
			padding-left: px(0);
		}

		.item {
			float: none;
			width: 100%;

			.link {
				table-layout: fixed;

				.col-left {
					width: px(60);
				}
			}
		}
	}
}

.list-bullet {
	li {
		display: block;
		position: relative;
		padding-left: px(15);

		&:not(:last-child) {
			margin-bottom: px(5);
		}

		&:before {
			content: "";
			width: px(6);
			height: px(6);
			background-color: shade($gy-a, 50%);
			border-radius: 6px;
			position: absolute;
			top: px(7);
			left: 0;
			display: block;
		}
	}
}

.home-goal-wp {
	padding-top: px(150);
	position: relative;
	background-image: url("../images/illustrations/bg-home-middle.png");
	background-repeat: no-repeat;
	background-position: center top;

	.link-cta {
		display: block;
		position: relative;
		background-color: $wh-a;
		box-shadow: 0 5px 15px rgba(0,0,0,0.1);
		border-radius: 5px;
		padding: px(45) px(20) px(20);
		height: 100%;
		transition: all 0.2s ease-in-out;

		&:focus,
		&:hover {
			box-shadow: 0 5px 15px rgba(0,0,0,0.25);

			.sprite {
				transform: scale(0.7);
			}
		}

		.sprite {
			display: block;
			position: absolute;
			top: px(-5);
			right: px(-5);
			transform: scale(0.8);
			transition: all 0.2s ease-in-out;
		}

		.title {
			color: $bl-a;
			font-weight: 700;
			margin-bottom: px(13);
			font-size: px(15);
			line-height: 1.2em;
			padding-right: px(75);
		}

		.txt {
			margin-bottom: 0;
			font-size: px(12);
		}
	}

	.items-wp {
		margin-top: px(180);
	}
	@include media-breakpoint-down(md) {
		.items-wp {
			margin-top: px(160);
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: px(100);

		.items-wp {
			margin-top: px(20);
		}
	}
	@include media-breakpoint-down(xs) {
		padding-top: px(50);

		.items-wp {
			margin-top: px(20);
		}
	}
}

.testiominals-wp {
	.bg-wp {
		position: relative;
		height: px(600);

		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: -50px;
			display: block;
			width: px(830);
			height: 100%;
			background-image: url("../images/illustrations/bg-home-bottom.png");
			background-repeat: no-repeat;
			background-position: -40px center;
			padding: 0;
		}
	}

	.round-wp {
		width: px(95);
		height: px(95);
		border-radius: 100%;
		font-size: px(40);
		font-weight: 400;
		color: $wh-a;
		text-transform: uppercase;
		text-align: center;
		padding-top: px(17);

		&.first {
			background-color: #ecd9b2;
		}

		&.second {
			background-color: #e2ecb2;
		}

		&.third {
			background-color: #b2dbec;
		}
	}

	.list {
		.item {
			position: relative;
			padding-left: px(115);
			padding-top: px(5);

			&:not(:last-child) {
				margin-bottom: px(30);
			}

			.round-wp {
				position: absolute;
				top: 0;
				left: 0;
			}

			.title {
				font-weight: 700;
				margin-bottom: px(5);
			}
		}
	}
}

.clients-link {
	display: block;
	color: $bl-a !important;
	font-size: px(13);
	font-weight: 600;
	text-decoration: underline !important;
}

.resa-step-3 {
	padding: 25px 30px 35px;
	background-color: $wh-a;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);

	&.except-table {
		padding: 0;
		overflow: hidden;
	}

	&.except-b {
		padding-bottom: 25px;
	}

	.label-bis {
		font-size: px(13);
		color: $bl-a;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 2px;
	}

	.form-control-bis {
		display: block;
		width: 100%;
		border: none;
		border-bottom: 1px solid shade($gy-a, 10%);
		color: $bk-a;
		font-size: px(13);
		padding: 6px 0;
		outline: none !important;
		-webkit-appearance: none;
		border-radius: 0 !important;
		box-shadow: none !important;
	}

	textarea.form-control-bis {
		margin-top: 5px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.form-control-bis.active,
	.form-control-bis:focus {
		border-bottom-color: $bl-a;
		color: $bl-a;
	}

	.form-control-bis::placeholder {
		color: tint($bk-a, 50%);
		font-style: italic;
		font-size: px(13);
		opacity: 1;
		font-size: px(13);
	}

	.input-caption {
		font-size: px(11);
		font-style: italic;
		color: $gy-a;
		margin-top: 10px;
	}

	.dropdown-toggle {
		border: none;
		border-bottom: 1px solid $gy-a;
		border-radius: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.datepicker-wp .ui-datepicker-trigger {
		opacity: 0.4;
		right: 3px;
		margin-top: -14px;
	}

	.datepicker-wp .form-control-bis {
		padding-right: 25px;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 20px 25px !important;
	}
}

.item-contact {
	.title-contact {
		position: relative;
		padding-left: 23px;
		font-weight: 600;
		font-size: px(15);
		color: $bl-a;
	}

	.title-contact .material-icons {
		position: absolute;
		color: $bl-a;
		top: 1px;
		left: -2px;
		font-size: px(18);
	}

	.list {
		margin-top: 8px;
		padding-left: 25px;
	}

	.list li {
		display: block;
		position: relative;
		padding-left: 20px;
		font-size: px(14);
		line-height: 1.4em;
		word-break: break-word;
	}

	.list li:not(:last-child) {
		margin-bottom: 7px;
	}

	.list li:before {
		content: "";
		position: absolute;
		top: 8px;
		left: 0;
		width: 8px;
		height: 2px;
		background-color: $bl-a;
	}
	@include media-breakpoint-down(sm) {
		float: left;
		width: 50%;
	}
	@include media-breakpoint-down(xs) {
		float: left;
		width: 100%;
	}
}

#header-page {
	background-color: $gy-a;
	padding: px(70) 0;
	text-align: center;
	position: relative;

	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		left: 0;
		background-image: url("../images/illustrations/bg-header.jpg");
		background-size: cover;
		opacity: 0.3;
	}

	.title {
		position: relative;
		z-index: 3;
		font-size: px(30);
		font-weight: 500;
		color: $bl-a;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0;
	}
	@include media-breakpoint-down(md) {
		padding: px(40) 0;

		.title {
			font-size: px(26);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(30) 0;

		.title {
			font-size: px(24);
		}
	}
	@include media-breakpoint-down(xs) {
		.title {
			font-size: px(20);
		}
	}
}

.chekbox-wpwpwp {
	> * {
		display: inline-block;
		vertical-align: middle;

		&:not(:last-child) {
			margin-right: px(10);
		}
	}
}

.form-nav {
	position: relative;
	z-index: 2;

	.btn-wp {
		background-color: $wh-a;
		width: 50%;
		color: $bl-a;
		font-weight: 700;
		text-transform: uppercase;
		font-size: px(14);
		float: left;
		border-radius: 8px 8px 0 0;
		padding: px(12) px(5);
		line-height: 1.1em;
		background-color: $bl-a;
		color: $wh-a;

		&.active {
			background-color: $wh-a;
			color: $bl-a;
			box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.1);
		}
		@include media-breakpoint-down(xs) {
			font-size: px(12);
		}
	}
}

.logo-item {
	display: block;
	position: relative;
	text-align: center;
	background-color: $wh-a;
	border-radius: 10px;
	transition: all 0.2s ease-in-out;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);

	figure {
		padding: px(15);
	}

	img {
		display: inline-block;
		transition: all 0.2s ease-in-out;
	}

	.caption {
		color: $bl-a;
		background-color: $gy-a;
		text-transform: uppercase;
		font-weight: 700;
		line-height: 1.15em;
		font-size: px(14);
		height: px(60);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: px(0) px(15);
	}
}

.blog-item {
	position: relative;
	display: block;
	background-color: $wh-a;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	height: 100%;
	transition: all 0.2s ease-in-out;
	.link {
		display: block;
	}
	.btn-full {
		font-size: px(12);
	}

	.col-bottom {
		padding: px(20) px(20) px(70);
	}

	figure {
		display: block;
		overflow: hidden;
	}

	img {
		display: block;
		transition: all 0.2s ease-in-out;
	}

	.date {
		background-color: $bl-a;
		line-height: 1em;
		color: $wh-a;
		border-radius: 2px;
		text-align: center;
		font-size: px(11);
		padding: px(4) px(6);
		text-transform: uppercase;
		font-weight: 600;
		display: inline-block;
	}

	.title {
		font-size: px(17);
		color: $bl-a;
		margin: px(8) 0 px(10);
		font-weight: 700;
		line-height: 1.2em;
	}

	.btn-wp-wp {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 0 px(20) px(20) 0;
		width: 100%;

		.btn-a {
			font-size: px(14);
			padding: px(8) px(15);
		}
	}

	&:focus,
	&:hover {
		img {
			transform: scale(1.1);
		}

		.btn-a {
			background-color: $bl-a;
			color: $wh-a;
		}
	}
}
.nav-filter {
	border-radius: 10px;
	overflow: hidden;
	border: 1px solid shade($gy-a, 10%);
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	.item {
		border-bottom: 1px solid shade($gy-a, 10%);
		&:last-child {
			border-bottom: none;
		}
	}
	.link {
		position: relative;
		padding: px(15) px(30) px(15) px(15);
		text-transform: uppercase;
		color: $bl-a;
		line-height: 1.1em;
		font-weight: 700;
		display: block;
		background-color: $gy-a;
		transition: all 0.2s ease-in-out;
		&:hover {
			background-color: $bl-a;
			color: $wh-a;
		}
		.material-icons {
			font-size: px(20);
			position: absolute;
			top: 50%;
			right: px(5);
			margin-top: px(-10);
		}
	}
}
