// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-a {
	display: inline-block;
	border-style: solid;
	border-width: 2px;
	min-width: px(180);
	padding: px(10) px(20);
	font-size: px(14);
	text-align: center;
	border-radius: px(40);
	font-weight: 600;
	transition: all 0.2s ease-in-out;
	&.white {
		border-color: $wh-a;
		color: $wh-a;
		&:focus,
		&:hover {
			background-color: $wh-a;
			color: $bl-a;
		}
	}
	&.blue {
		border-color: $bl-a;
		color: $bl-a;
		&:focus,
		&:hover {
			background-color: $bl-a;
			color: $wh-a;
		}
	}
	&.white-full {
		border-color: $wh-a;
		background-color: $wh-a;
		color: $bl-a;
		box-shadow: 4px 4px 10px rgba(0,0,0,0);
		&:focus,
		&:hover {
			box-shadow: 4px 4px 10px rgba(0,0,0,0.3);
		}
	}
	&.blue-full {
		border-color: $bl-a;
		background-color: $bl-a;
		color: $wh-a;
		box-shadow: 4px 4px 10px rgba(0,0,0,0);
		&:focus,
		&:hover {
			box-shadow: 4px 4px 10px rgba(0,0,0,0.3);
		}
	}
}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 30px;
	height: 20px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $wh-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 9px;
	}

	span:nth-child(4) {
		top: 18px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null 30px 30px null);
	z-index: 99;
	transition: all 0.2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		right: 15px;
		bottom: 15px;
	}
}

.pagination-wp {
	margin-top: px(30);
	@include media-breakpoint-down(sm) {
		margin-top: px(20);
	}
	.pagination {
		justify-content: center;
		.page-link {
			padding: px(6) px(11);
			border: none !important;
			color: $bl-a;
			background-color: $gy-a;
			font-weight: 400;
			font-size: px(15);
			margin-left: px(1);
		}
		.page-item.active .page-link {
			background-color: $bl-a;
			color: $wh-a;
		}
	}

}
