$bk-a: #000000;
$gy-a: #f6f6f6;
$gy-b: #3c3c3c;
$wh-a: #FFFFFF;
$bl-a: #254fa4;
$bl-b: #91a7d1;
$or-a: #f4842e;

.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}

.c-gy-a {
  color: $gy-a;
}

.c-gy-b {
  color: $gy-b;
}

.c-bl-a {
  color: $bl-a;
}

.c-bl-b {
  color: $bl-b;
}

.c-or-a {
  color: $or-a;
}
// Background

.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-gy-b {
  background-color: $gy-b;
}

.bg-bl-a {
  background-color: $bl-a;
}

.bg-bl-b {
  background-color: $bl-b;
}

.bg-or-a {
  background-color: $or-a;
}
